import React, { useState, useEffect } from 'react';
import { listarClientes } from '../services/api';

const CadastroPdv = ({ token, initialData = {}, onSubmit, isEdit = false }) => {
  const [clientes, setClientes] = useState([]);
  const [pdv, setPdv] = useState({
    idCliente: '',
    descricao: '',
    codigoInstalacao: '',
    qtdLicencas: 1,
    ...initialData
  });

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      setPdv({
        idCliente: initialData.idCliente || '',
        descricao: initialData.descricao || '',
        codigoInstalacao: initialData.codigoInstalacao || '',
        qtdLicencas: initialData.qtdLicencas || 1,
      });
    }
  }, [initialData]);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await listarClientes(token);
        setClientes(response.data);
      } catch (error) {
        console.error('Erro ao listar clientes:', error);
      }
    };
    fetchClientes();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPdv({ ...pdv, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const pdvPayload = {
      IdCliente: pdv.idCliente,
      Descricao: pdv.descricao,
      CodigoInstalacao: pdv.codigoInstalacao,
      QtdLicencas: parseInt(pdv.qtdLicencas, 10),
    };
  
    try {
      await onSubmit(pdvPayload, token);
      alert(isEdit ? 'PDV atualizado com sucesso!' : 'PDV cadastrado com sucesso!');
    } catch (error) {
      if (error.response) {
        console.error(`Erro ao ${isEdit ? 'atualizar' : 'cadastrar'} PDV:`, error.response.data);
        alert(`Erro ao ${isEdit ? 'atualizar' : 'cadastrar'} PDV: ${JSON.stringify(error.response.data)}`);
      } else {
        console.error(`Erro ao ${isEdit ? 'atualizar' : 'cadastrar'} PDV`, error);
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <h2 className="d-flex justify-content-center">{isEdit ? 'Editar PDV' : 'Cadastro de PDV'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="idCliente">Cliente</label>
            <select
              className="form-control"
              id="idCliente"
              name="idCliente"
              value={pdv.idCliente}
              onChange={handleChange}
              required
            >
              <option value="">Selecione um cliente</option>
              {clientes.map((cliente) => (
                <option key={cliente.id} value={cliente.id}>
                  {cliente.razaoSocial}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="descricao">Descrição</label>
            <input
              type="text"
              className="form-control"
              id="descricao"
              name="descricao"
              value={pdv.descricao}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="codigoInstalacao">Código de Instalação</label>
            <input
              type="text"
              className="form-control"
              id="codigoInstalacao"
              name="codigoInstalacao"
              value={pdv.codigoInstalacao}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="qtdLicencas">Quantidade de Licenças</label>
            <input
              type="number"
              className="form-control"
              id="qtdLicencas"
              name="qtdLicencas"
              value={pdv.qtdLicencas}
              onChange={handleChange}
              min="1"
              required
            />
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-primary mt-2">{isEdit ? 'Salvar' : 'Cadastrar PDV'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CadastroPdv;
