import React, { useState } from 'react';
import { uploadCertificado } from '../services/api';

const UploadCertificado = ({ token }) => {
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file || !password) {
      setMessage("Por favor, selecione um arquivo e insira uma senha.");
      return;
    }

    try {
      const response = await uploadCertificado(file, password, token);
      setMessage(response.data);
    } catch (error) {
      setMessage("Erro ao fazer upload do certificado.");
      console.error(error);
    }
  };

  return (
    <div>
      <h2>Upload de Certificado Digital</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Certificado (PFX):</label>
          <input type="file" onChange={handleFileChange} />
        </div>
        <div>
          <label>Senha:</label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <button type="submit">Enviar</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default UploadCertificado;
