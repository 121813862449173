import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// Função para verificar se o token está expirado
const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Hora atual em segundos
    return decoded.exp < currentTime; // Retorna true se o token expirou
  } catch (error) {
    return true; // Se não conseguir decodificar, trata como expirado
  }
};

// Função para configurar interceptors e passar o navigate como argumento
export const setupInterceptors = (navigate) => {
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      
      if (token && isTokenExpired(token)) {
        localStorage.removeItem('token');
        localStorage.removeItem('userName');
        navigate('/login'); // Redireciona para a página de login
        return Promise.reject(new Error('Token expirado, redirecionando para login'));
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Criação da instância Axios
const api = axios.create({
  baseURL: 'https://licenciamento.zenpdv.com/api/v1',
});

// Autenticação
export const login = (data) => api.post('/account/login', data);

// Clientes
export const cadastrarCliente = (data, token) => 
  api.post('/clientes', data, { headers: { Authorization: `Bearer ${token}` } });

export const listarClientes = (token) =>
  api.get('/clientes', { headers: { Authorization: `Bearer ${token}` } });

export const obterClientePorId = (id, token) => 
  api.get(`/clientes/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const editarCliente = (id, data, token) =>
  api.put(`/clientes/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });

export const excluirCliente = (id, token) =>
  api.delete(`/clientes/${id}`, { headers: { Authorization: `Bearer ${token}` } });

// PDVs
export const listarPdvs = (token) =>
  api.get('/pdvs', { headers: { Authorization: `Bearer ${token}` } });

export const gerarLicenca = (id, data, token) =>
  api.put(`/pdvs/gerar-chave/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });

export const baixarChave = (id, token) =>
  api.get(`/pdvs/${id}/baixar-chave`, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' });

export const cadastrarPdv = (data, token) => 
  api.post('/pdvs', data, { headers: { Authorization: `Bearer ${token}` } });

export const excluirPdv = (id, token) =>
  api.delete(`/pdvs/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const obterPdvPorId = (id, token) =>
  api.get(`/pdvs/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const editarPdv = (id, data, token) =>
  api.put(`/pdvs/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });


// Usuários
export const listarUsuarios = (token) =>
  api.get('/usuarios', { headers: { Authorization: `Bearer ${token}` } });

export const obterUsuarioPorId = (id, token) =>
  api.get(`/usuarios/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const cadastrarUsuario = (data, token) =>
  api.post('/usuarios', data, { headers: { Authorization: `Bearer ${token}` } });

export const editarUsuario = (id, data, token) =>
  api.put(`/usuarios/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });

export const excluirUsuario = (id, token) =>
  api.delete(`/usuarios/${id}`, { headers: { Authorization: `Bearer ${token}` } });

// Home
export const getQuantidadeClientes = (token) => {
  return api.get('/clientes/quantidade', { headers: { Authorization: `Bearer ${token}` } });
};

export const getQuantidadePdvs = (token) => {
  return api.get('/pdvs/quantidade', { headers: { Authorization: `Bearer ${token}` } });
};

export const getQuantidadeChavesBloqueadas = (token) => {
  return api.get('/pdvs/quantidade-chaves-bloqueadas', { headers: { Authorization: `Bearer ${token}` } });
};

export const getQuantidadeChavesAtualizadas = (token) => {
  return api.get('/pdvs/quantidade-chaves-atualizadas', { headers: { Authorization: `Bearer ${token}` } });
};

//Certificado

export const uploadCertificado = (file, password, token) => {
  const formData = new FormData();
  formData.append("certificado", file);
  formData.append("senha", password);

  return api.post("/certificados/upload", formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
};


export default api;
