import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Login from './components/Login';
import NovoCliente from './components/NovoCliente';
import ListaClientes from './components/ListaClientes';
import ListaUsuarios from './components/ListaUsuarios';
import GerarChave from './components/GerarChave';
import EditarCliente from './components/EditarCliente';
import EditarUsuario from './components/EditarUsuario';
import NovoUsuario from './components/NovoUsuario';
import EditarPdv from './components/EditarPdv'; 
import NovoPDV from './components/NovoPdv';
import UploadCertificado from './components/UploadCertificado';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userName, setUserName] = useState(localStorage.getItem('userName') || '');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    setToken(null);
    setUserName('');
    window.location.href = '/login';
  };

  return (
    <Router>
      {token && <Navbar userName={userName} onLogout={handleLogout} />}
      <Routes>
        <Route path="/login" element={!token ? <Login setToken={setToken} setUserName={setUserName} /> : <Navigate to="/home" />} />
        <Route path="/clientes/novo" element={token ? <NovoCliente token={token} /> : <Navigate to="/login" />} /> 
        <Route path='/usuarios/novo' element={token ? <NovoUsuario token={token} /> : <Navigate to="/login" />} />
        <Route path="/clientes/editar/:id" element={token ? <EditarCliente token={token} /> : <Navigate to="/login" />} />
        <Route path="/usuarios/editar/:id" element={token ? <EditarUsuario token={token} /> : <Navigate to="/login" />} />
        <Route path="/clientes" element={token ? <ListaClientes token={token} /> : <Navigate to="/login" />} />
        <Route path="/usuarios" element={token ? <ListaUsuarios token={token} /> : <Navigate to="/login" />} />
        <Route path="/pdvs/gerar-chave/:id" element={token ? <GerarChave token={token} /> : <Navigate to="/login" />} />
        <Route path="/pdvs/gerar-chave" element={token ? <GerarChave token={token} /> : <Navigate to="/login" />} />
        <Route path="/pdvs/novo" element={token ? <NovoPDV token={token} /> : <Navigate to="/login" />} />  
        <Route path="/pdvs/editar/:id" element={token ? <EditarPdv token={token} /> : <Navigate to="/login" />} />
        <Route path="/home" element={token ? <Home token={token} /> : <Navigate to="/login" />} />
        <Route path="/" element={token ? <Navigate to="/home" /> : <Navigate to="/login" />} />
        <Route path="/certificados/upload" element={token ? <UploadCertificado token={token} /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
