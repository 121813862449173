import React, { useEffect, useState } from 'react';
import { listarPdvs, gerarLicenca, excluirPdv } from '../services/api';
import { useNavigate } from 'react-router-dom';

const GerarChave = ({ token }) => {
  const [pdvs, setPdvs] = useState([]);
  const [filteredPdvs, setFilteredPdvs] = useState([]);
  const [selectedPdvs, setSelectedPdvs] = useState([]);
  const [validade, setValidade] = useState('');
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState('');
  const [selectedClientName, setSelectedClientName] = useState('');
  const navigate = useNavigate();

  const fetchPdvs = async () => {
    try {
      const response = await listarPdvs(token);
      setPdvs(response.data);
      setFilteredPdvs(response.data);
    } catch (error) {
      console.error('Erro ao listar PDVs:', error);
    }
  };

  useEffect(() => {
    fetchPdvs();
  }, [token]);

  useEffect(() => {
    setFilteredPdvs(
      pdvs.filter(pdv => pdv.clienteNome.toLowerCase().includes(filter.toLowerCase()))
    );
  }, [filter, pdvs]);

  const handleCheckboxChange = (id) => {
    setSelectedPdvs(prev =>
      prev.includes(id) ? prev.filter(pdvId => pdvId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allPdvIds = filteredPdvs.map(pdv => pdv.id);
      setSelectedPdvs(allPdvIds);
    } else {
      setSelectedPdvs([]);
    }
  };

  const handleGerarChave = async () => {
    try {
      for (const pdvId of selectedPdvs) {
        const pdv = filteredPdvs.find(p => p.id === pdvId);

        if (pdv) {
          const pdvData = {
            Descricao: pdv.descricao,
            CodigoInstalacao: pdv.codigoInstalacao,
            ValidadeChave: validade,
            QtdLicencas: pdv.qtdLicencas,
          };

          console.log(`Dados enviados para gerar licença:`, { id: pdv.id, ...pdvData });
          await gerarLicenca(pdv.id, pdvData, token);
        }
      }
      alert('Chaves geradas com sucesso!');
      fetchPdvs();  // Atualiza a lista após gerar a chave
    } catch (error) {
      console.error('Erro ao gerar chaves:', error);
      alert('Erro ao gerar chaves.');
    }
  };

  const handleEdit = (id) => {
    navigate(`/pdvs/editar/${id}`);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Tem certeza que deseja excluir este PDV?");
    if (confirmDelete) {
      excluirPdv(id, token)
        .then(() => {
          alert('PDV excluído com sucesso!');
          fetchPdvs(); // Atualizar a lista de PDVs após a exclusão
        })
        .catch((error) => {
          console.error('Erro ao excluir PDV:', error);
          alert('Erro ao excluir PDV.');
        });
    }
  };

  const handleShowSignature = (signature, clientName) => {
    setSelectedSignature(signature);
    setSelectedClientName(clientName);
    setShowModal(true);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedSignature);
    alert('Assinatura copiada para a área de transferência!');
  };

  return (
    <div className="container mt-5">
      <h2>Licenças - Todos os PDVs</h2>
      <div className="form-group">
        <label>Filtrar por Cliente:</label>
        <input
          type="text"
          className="form-control"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Digite o nome do cliente"
        />
      </div>
      <table className="table table-striped mt-3">
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedPdvs.length === filteredPdvs.length && filteredPdvs.length > 0}
              />
            </th>
            <th>Cliente</th>
            <th>Descrição</th>
            <th>Código de Instalação</th>
            <th>Qtd. de Licenças</th>
            <th>Data de Vencimento</th>
            <th>Chave Atual</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredPdvs.map((pdv) => (
            <tr key={pdv.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedPdvs.includes(pdv.id)}
                  onChange={() => handleCheckboxChange(pdv.id)}
                />
              </td>
              <td>{pdv.clienteNome}</td>
              <td>{pdv.descricao}</td>
              <td>{pdv.codigoInstalacao}</td>
              <td>{pdv.qtdLicencas}</td>
              <td>{new Date(pdv.validadeChave).toLocaleDateString('pt-BR')}</td>
              <td>{pdv.chaveAtivacao}</td>       
              <td>
                <div className="btn-group" role="group">
                  <button
                    className="btn btn-info btn-sm"
                    onClick={() => handleShowSignature(pdv.assinaturaSat, pdv.clienteNome)}
                  >
                    Assinatura SAT
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleEdit(pdv.id)}
                  >
                    Alterar Dados
                  </button>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => handleDelete(pdv.id)}
                  >
                    Excluir
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="form-group mt-3">
        <label>Nova Data de Validade:</label>
        <input
          type="date"
          className="form-control"
          value={validade}
          onChange={(e) => setValidade(e.target.value)}
          required
        />
      </div>
      <button className="btn btn-success mt-3" onClick={handleGerarChave}>Gerar Nova Chave</button>

      {/* Modal para exibir a assinatura SAT */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h5>Assinatura SAT</h5>
            <h6>Cliente: {selectedClientName}</h6>
            <textarea
              readOnly
              value={selectedSignature}
              className="form-control"
              style={{ height: '100px' }}
              onFocus={(e) => e.target.select()}
            />
            <button className="btn btn-primary mt-3" onClick={handleCopy}>Copiar</button>
            <button className="btn btn-secondary mt-3" onClick={() => setShowModal(false)}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GerarChave;
